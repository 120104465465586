<!-- 对账资料弹窗 -->
<template>
  <el-dialog
    width="40%"
    center
    :visible="visible"
    :lock-scroll="false"
    :destroy-on-close="true"
    custom-class="ele-dialog-form"
    :title="isUpdate?'对账资料':'对账资料'"
    @update:visible="updateVisible">
    <el-form
      ref="form"
      :model="form"
      label-width="120px">

      <div style="border-bottom: 1px solid #eeeeee;">
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
          <span style="font-size: 18px;font-weight: 700;color: #3f4155;">开票信息</span>
        </div>
        <el-row :gutter="15" style="margin-top: 15px;">
          <el-col :span="12">
            <el-form-item label="客户名称：" style="margin-bottom: 0!important;">
              <span>{{form.InvoiceInformation.company_name}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开票税号：" style="margin-bottom: 0!important;">
              <span>{{form.InvoiceInformation.tax_number}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="电话号码：" style="margin-bottom: 0!important;">
              <span>{{form.InvoiceInformation.phone}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开户银行：" style="margin-bottom: 0!important;">
              <span>{{form.InvoiceInformation.deposit_bank}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="银行账号：" style="margin-bottom: 0!important;">
              <span>{{form.InvoiceInformation.bank_number}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企业地址：" style="margin-bottom: 0!important;">
              <span>{{form.InvoiceInformation.enterprise_address}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="24">
            <el-form-item label="邮寄地址：" style="margin-bottom: 0!important;">
              {{form.InvoiceInformation.delivery_address}}
            </el-form-item>
          </el-col>
        </el-row>
      </div>

      <div style="margin-top: 20px;">
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
          <span style="font-size: 18px;font-weight: 700;color: #3f4155;">收款信息</span>
        </div>
        <el-row :gutter="15" style="margin-top: 15px;">
          <el-col :span="12">
            <el-form-item label="开户行：" style="margin-bottom: 0!important;">
              <span>{{form.CollectionProfile.tax_number}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="户名：" style="margin-bottom: 0!important;">
              <span>{{form.CollectionProfile.bank_number}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="账号：" style="margin-bottom: 0!important;">
              <span>{{form.CollectionProfile.account_holder}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发票：" style="margin-bottom: 0!important;">
              <div style="display: flex;align-items: center;" @click="open()">
                <span style="color: #FF9B05;">下载</span>
                <img src="@/assets/images/home/caiwu/xiazai.png" alt="" style="width: 23px;height: 21px;margin-left: 15px;">
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="付款凭证：" style="margin-bottom: 0!important;">
              <div style="display: flex;align-items: center;" @click="open1()">
                <span style="color: #FF9B05;">下载</span>
                <img src="@/assets/images/home/caiwu/xiazai.png" alt="" style="width: 23px;height: 21px;margin-left: 15px;">
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </div>

    </el-form>


    <div slot="footer">
      <el-button
        @click="updateVisible(false)">取消
      </el-button>
      <el-button
        style="margin-left: 20px;"
        type="primary"
        :loading="loading"
        @click="save">确定
      </el-button>
    </div>

  </el-dialog>
</template>

<script>
import {get_invoice_information} from "@/api/finance";
export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {
    this.getSshuju()
  },
  methods: {
    //获取数据
    getSshuju(){
      get_invoice_information().then(res => {
        console.log(res)
        this.form = res.data;
      })
    },

    /* 保存编辑 */
    save() {
      this.updateVisible(false);
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //发票下载
    open(){
      window.open(this.form.info.invoice);
    },

    //凭证下载
    open1(){
      window.open(this.form.info.payment_proof);
    },


  }
}
</script>

<style scoped lang="scss">

</style>
